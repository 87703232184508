import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { addLocaleData } from 'react-intl';
import ConnectedIntlProvider from './components/ConnectedIntProvider';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-input-range/lib/css/index.css';

import axiosMiddleware from 'redux-axios-middleware';
import en from 'react-intl/locale-data/en';
import hr from 'react-intl/locale-data/hr';
import de from 'react-intl/locale-data/de';
import fr from 'react-intl/locale-data/fr';
import es from 'react-intl/locale-data/es';
import it from 'react-intl/locale-data/it';
import pl from 'react-intl/locale-data/pl';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './reducers';
import { getUserLocale } from './reducers/settings/thunks';
import client from './axios';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

Sentry.init({ dsn: "https://6c184ec422cb411597321253f07b338d@sentry.io/1501194" });

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, axiosMiddleware(client)))
);

const persistor = persistStore(store)

addLocaleData([...en, ...hr, ...de, ...fr, ...es, ...it, ...pl]);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} onBeforeLift={() => store.dispatch(getUserLocale())} >
      <ConnectedIntlProvider>
        <App />
      </ConnectedIntlProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import Menu from './components/Header/Menu';
import Footer from './components/Footer';
import history from './components/History';
import ScrollToTop from './components/ScrollToTop';
import { isHomePageBannerActive } from './utils/featureToggle';
import featureConfig from '../features.json';
import CookiesGDPR from './components/CookiesGDPR';

const Home = lazy(() => import('./containers/Home'));
const Accommodation = lazy(() => import('./containers/Accommodation'));
const SearchPage = lazy(() => import('./containers/SearchPage'));
const GroupTravel = lazy(() => import('./containers/GroupTravel'));
const ChristmasBetanija = lazy(() => import('./containers/ChristmasBetanija'));
const PrivacyPolicy = lazy(() => import('./containers/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./containers/TermsOfService'));
const Cookies = lazy(() => import('./containers/Cookies'));
const Offers = lazy(() => import('./containers/Offers'));
const OfferDetails = lazy(() => import('./containers/OfferDetails'));

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <ScrollToTop>
          <Menu />
          <Suspense fallback={<div />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                path={['/accommodation/:id', '/accommodationbooking/:id']}
                component={Accommodation}
              />
              <Route path="/search" component={SearchPage} />
              <Route path="/group" component={GroupTravel} />
              <Route path="/deals" component={Offers} />
              <Route path="/deal/:id" component={OfferDetails} />
              {isHomePageBannerActive() && (
                <Route
                  path={featureConfig.homepageBanner.link}
                  component={ChristmasBetanija}
                />
              )}
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/cookies" component={Cookies} />
              <Route path="/terms-of-service" component={TermsOfService} />
              <Route path="*" component={Home} />
            </Switch>
          </Suspense>
          <Footer />
          <CookiesGDPR />
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;

import { GET_USER_LOCALES, CHANGE_LOCALE } from '../constants/actionTypes';

const defaultState = {
  language: 'hr',
  messages: {},
};

const locales = (state = defaultState, { type, ...payload }) => {
  switch (type) {
    case GET_USER_LOCALES:
      return {
        ...state,
        messages: payload.messages,
        language: payload.language,
      };
    case CHANGE_LOCALE:
      return {
        ...state,
        messages: payload.messages,
        language: payload.language,
      };
    default:
      return state;
  }
};

export default locales;

import moment from 'moment';

export const formatReverseDate = date => {
  return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
};

export const formatDate = date => {
  return moment(date, 'DD-MM-YYYY');
};

export const qsDecoder = value => {
  if (/^(\d+|\d*\.\d+)$/.test(value)) {
    return parseFloat(value);
  }

  let keywords = {
    true: true,
    false: false,
    null: null,
    undefined: undefined,
  };
  if (value in keywords) {
    return keywords[value];
  }

  if (typeof value === 'string') value = decodeURIComponent(value);

  return value;
};

export const getCalculateData = (
  persons,
  selectedOptions,
  dateFrom,
  dateTo,
  availableSuitesArr,
  accommodationProviderId,
) => {
  let rentableObjects = availableSuitesArr.map(suite => {
    return {
      id: suite.id,
      type: suite.type,
      price: suite.price.amount,
      suitesCount: suite.selectedCount,
    };
  });
  let additionalServicesAndContent = {};
  selectedOptions.forEach(option => {
    let tmp = {
      repeatsDaily: option.isRepeatingDaily,
    };
    additionalServicesAndContent[option.id] = tmp;
  });

  let suitesInfo = {};
  rentableObjects.forEach(suite => {
    let tmp = {
      count: suite.suitesCount,
    };
    suitesInfo[suite.id] = tmp;
  });
  const formData = {
    accommodationProviderId,
    suitesInfo,
    rentableObjects,
    dateFrom,
    dateTo,
    numberOfPersons: persons,
    additionalServicesAndContent,
  };

  return formData;
};

export const getCountyCodeByIpLookup = () => {
  return fetch('http://ip-api.com/json')
    .then(res => res.json())
    .then(response => {
      return response.countryCode && response.countryCode.toLowerCase();
    })
    .catch(err => {
      fetch('https://extreme-ip-lookup.com/json/')
        .then(res => res.json())
        .then(response => {
          return response.countryCode && response.countryCode.toLowerCase();
        })
        .catch(() => null);
    });
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useGeolocation } from '../../utils/useGeolocation';
import { Colors, MobileHidden, MobileVisible } from '../../theme';

import LanguageSwitcher from '../Header/LanguageSwitcher';

const Separator = styled.hr`
  margin-top: 36px;
  margin-bottom: 36px;
`;

const StyledLink = styled(Link)`
  text-align: center;
  margin-top: 7px;
  :hover {
    text-decoration: none;
  }
`;

const StyledHref = styled.a`
  :hover {
    text-decoration: none;
  }
`;

const LinkText = styled.div`
  display: inline;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration-line: underline;
  color: #19b1c9;
  :hover {
    text-decoration: underline;
    color: ${Colors.Orange};
  }
`;

const BigText = styled.span`
  margin-bottom: 12px;
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #505050;
`;

const Footer = () => {
  const { countryCode } = useGeolocation();

  return (
    <footer style={{ marginTop: '36px' }}>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-4 d-flex flex-column align-items-center">
          <MobileHidden>
            <img
              style={{ maxWidth: '224px', marginBottom: '16px' }}
              src="/img/monastays-logo-2.svg"
              alt="Monastays logo"
            />
          </MobileHidden>
          <StyledLink to="/">
            <LinkText>
              <FormattedMessage id="Menu.home" defaultMessage="Home" />
            </LinkText>
          </StyledLink>
          <StyledLink to="/deals">
            <LinkText>
              <FormattedMessage id="Menu.offers" defaultMessage="Offers" />
            </LinkText>
          </StyledLink>
          <StyledLink to="/search">
            <LinkText>
              <FormattedMessage id="Menu.Search" defaultMessage="Monasteries" />
            </LinkText>
          </StyledLink>
          <StyledHref
            href="https://monastays-blog.webflow.io"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginTop: '7px' }}
          >
            <LinkText>
              <FormattedMessage id="Menu.blog" defaultMessage="Blog" />
            </LinkText>
          </StyledHref>
        </div>
        <MobileVisible>
          <div className="col-md-12">
            <Separator />
          </div>
        </MobileVisible>
        <div className="col-md-4 d-flex flex-column align-items-center">
          <MobileVisible>
            <div style={{ marginBottom: '24px', maxWidth: '224px' }}>
              <img src="/img/monastays-logo-2.svg" alt="Monastays logo" />
            </div>
          </MobileVisible>
          <BigText>
            <FormattedMessage
              id="Footer.contactUs"
              defaultMessage="Contact us"
            />
            {':'}
          </BigText>
          <StyledHref href="tel: +385919708853" style={{ marginBottom: '6px' }}>
            <img src="/img/phone.svg" alt="" />{' '}
            <LinkText>
              <FormattedMessage
                id="Footer.phone"
                defaultMessage="+385 91 970 8853"
              />
            </LinkText>
          </StyledHref>
          <StyledHref href="mailto: info@monastays.com">
            <img src="/img/mail.svg" alt="" />{' '}
            <LinkText>
              <FormattedMessage
                id="Footer.email"
                defaultMessage="info@monastays.com"
              />
            </LinkText>
          </StyledHref>
          <BigText style={{ marginTop: '24px' }}>
            <FormattedMessage id="Footer.visitUs" defaultMessage="Visit Us" />
            {':'}
          </BigText>
          <StyledHref
            href="https://goo.gl/maps/57XwRuPq6nqavt43A"
            style={{ marginBottom: '24px' }}
          >
            <div style={{ width: '203px', textAlign: 'center' }}>
              <img src="/img/map-pin.svg" alt="" />{' '}
              <LinkText>
                <FormattedMessage
                  id="Footer.addressLine1"
                  defaultMessage="Technology Park Zagreb,"
                />{' '}
                <FormattedMessage
                  id="Footer.addressLine2"
                  defaultMessage="Slavonska Avenija 52M, Zagreb, Croatia"
                />
              </LinkText>
            </div>
          </StyledHref>
        </div>
        <div className="col-md-4 d-flex flex-column align-items-center">
          <BigText style={{ marginBottom: '24px' }}>
            <FormattedMessage
              id="Footer.socialNetworks"
              defaultMessage="Social networks"
            />
            {':'}
          </BigText>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              width: '142px',
            }}
          >
            <a
              href={
                countryCode === 'at'
                  ? 'https://www.facebook.com/Monastays-%C3%96sterreich-104641897754655'
                  : 'https://www.facebook.com/monastays/'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/img/facebook.svg" alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/mona_stays/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src="/img/instagram.svg" alt="instagram" />
            </a>
            <a
              href="https://www.google.com/search?q=monastays"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src="/img/google.svg" alt="google" />
            </a>
          </div>
          <BigText style={{ marginTop: '24px' }}>
            <FormattedMessage
              id="Footer.localeSelecton"
              defaultMessage="Select language"
            />
            {':'}
          </BigText>
          <div className="footer_translate_wrapper">
            <div className="d-inline-block d-lg-none">
              <li style={{ listStyleType: 'none' }}>
                <LanguageSwitcher mobile />
              </li>
            </div>
            <div className="d-none d-lg-inline-block">
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <Separator />
      </div>
      {/* <div
          className="row d-flex justify-content-center"
          style={{ margin: 0 }}
        >
          <img
            style={{ maxWidth: '224px', marginBottom: '20px' }}
            src="/img/zicer-logo.png"
            alt="Zicer logo"
          />
        </div> */}
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-4 d-flex justify-content-center">
          <p className="monastays_copy">
            <FormattedMessage
              id="Footer.copyright"
              defaultMessage="© 2019 Monastays | All Rights Reserved"
            />
          </p>
        </div>
        <div className="col-md-5 row d-flex justify-content-center">
          <Link to="/cookies">
            <p className="monastays_copy">
              <FormattedMessage id="Cookies.Title" />
            </p>
          </Link>
          <p className="monastays_copy" style={{ padding: '0 6px' }}>
            |
          </p>
          <Link to="/privacy-policy">
            <p className="monastays_copy">
              <FormattedMessage id="PrivacyPolicy.Title" />
            </p>
          </Link>
          <p className="monastays_copy" style={{ padding: '0 6px' }}>
            |
          </p>
          <Link to="/terms-of-service">
            <p className="monastays_copy">
              <FormattedMessage id="Tos.Title" />
            </p>
          </Link>
        </div>
        <div className="col-md-3 d-flex justify-content-center">
          <p className="monastays_copy monastays_text">
            <FormattedMessage
              id="Footer.craftedBy"
              defaultMessage="Crafted with love by"
            />
            &nbsp;
            <a
              className="monastays_footer_link"
              href="http://codein-consulting.hr/"
            >
              CodeIN
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

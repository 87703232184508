export const Devices = {
  Mobile: 'Mobile',
  Tablet: 'Tablet',
  Desktop: 'Desktop',
};

export const BreakPoints = {
  [Devices.Mobile]: 576,
  [Devices.Tablet]: 768,
  [Devices.Desktop]: 1200,
};

export const BreakPointsPx = {
  Mobile: `${BreakPoints.Mobile}px`,
};

export const Colors = {
  Blue: '#19B1C9',
  Orange: '#E2A915',
  Primary: '#19B1C9',
  White: '#FFFFFF',
  Dark: '#222222',
  DarkGray: '#505050',
  BorderGray: '#737373',
  BackgroundGray: '#F0F0F0',
  TextGray: '#999999',
};

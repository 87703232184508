import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.75);
  padding: 20px;
  z-index: 100;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  max-width: 1160px;
  display: flex;
  align-items: center;
  @media (max-width: 740px) {
    flex-direction: column;
  }
`
export const Text = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: white;
  margin-right: 20px;
  @media (max-width: 740px) {
    margin-bottom: 20px;
    text-align: center;
  }
`
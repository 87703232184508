import { REVIEWS_LOAD, REVIEWS_LOAD_SUCCESS, REVIEWS_LOAD_FAIL } from '../constants/actionTypes';

const defaultState = {
  reviewsList: [],
  loading: false,
  error: false,
};

const review = (state = defaultState, { type, error, payload }) => {
  switch (type) {
    case REVIEWS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case REVIEWS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewsList: payload.data,
      };
    case REVIEWS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: error,
      };
    default:
      return state;
  }
};

export default review;

import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LANGUAGES, DEFAULT_LANGUAGE } from '../../constants/languages';
import { switchUserLanguage } from '../../reducers/settings/thunks';
import { languageSelector } from '../../reducers/settings/selectors';

class LanguageSwitcher extends Component {
  state = {
    language: DEFAULT_LANGUAGE,
    showDropdown: false,
  };

  componentDidMount() {
    if (this.props.language) {
      this.setLanguageState(this.props);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.language !== this.props.language) {
      this.setLanguageState(newProps);
    }
  }

  setLanguageState = props => {
    const newLang = props.languages.filter(
      lang => lang.code === props.language,
    )[0];
    this.setState({ language: newLang });
  };

  handleClickOutside = () => {
    this.setState({ showDropdown: false });
  };

  handleChooseLang = (e, lang) => {
    e.preventDefault();
    if (lang === this.state.language) {
      return;
    }
    this.props.changeLocale(lang.code);
    this.setState({ showDropdown: false });
  };

  handleClick = e => {
    this.setState({ showDropdown: !this.state.showDropdown });
    e.preventDefault();
  };

  render() {
    const { showDropdown, language } = this.state;
    const { languages, mobile } = this.props;

    return (
      <>
        {mobile ? (
          <div>
            {languages.map(lang => (
              <li
                key={lang.code}
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <a
                  className="translate_link monastays_nav_link_translate no_arrow"
                  href="/"
                  onClick={e => this.handleChooseLang(e, lang)}
                >
                  <span className="badge badge-light">
                    <img
                      src={`/img/${lang.code}-flag.svg`}
                      alt={`${lang.code} flag`}
                    />
                  </span>
                  {lang.value}
                </a>
              </li>
            ))}
          </div>
        ) : (
          <div className="d-flex flex-column">
            <a
              className="translate_link drop_link monastays_nav_link_translate"
              href="/"
              onClick={this.handleClick}
            >
              <span className="badge badge-light">
                <img
                  src={`/img/${language.code}-flag.svg`}
                  alt={`${language.code} flag`}
                />
              </span>
              {language.value}
            </a>
            <div
              className="translate_dropdown"
              style={{ display: showDropdown ? 'block' : 'none' }}
            >
              {languages.map(lang => (
                <a
                  key={lang.code}
                  className="translate_link no_arrow"
                  href="/"
                  onClick={e => this.handleChooseLang(e, lang)}
                >
                  <span className="badge badge-light">
                    <img
                      src={`/img/${lang.code}-flag.svg`}
                      alt={`${lang.code} flag`}
                    />
                  </span>
                  {lang.value}
                </a>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
}

LanguageSwitcher.propTypes = {
  languages: PropTypes.array,
  mobile: PropTypes.bool,
};

LanguageSwitcher.defaultProps = {
  languages: LANGUAGES,
  mobile: false,
};

export default connect(
  state => ({
    language: languageSelector(state),
  }),
  {
    changeLocale: switchUserLanguage,
  },
)(onClickOutside(LanguageSwitcher));

import { useEffect, useState } from 'react';

import { getCountyCodeByIpLookup } from './index';

export const useGeolocation = () => {
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    getCountyCodeByIpLookup().then(countryCode => setCountryCode(countryCode));
  }, []);

  return { countryCode };
};

import React, { useState, useLayoutEffect, useCallback } from 'react';

import { BreakPoints, Devices } from '..';

const getIsMobile = () => window.outerWidth <= BreakPoints.Tablet;
const getIsTablet = () =>
  window.outerWidth > BreakPoints.Tablet &&
  window.outerWidth < BreakPoints.Desktop;
const getIsDesktop = () => window.outerWidth >= BreakPoints.Desktop;
const getCurrentDevice = () => {
  return getIsMobile()
    ? Devices.Mobile
    : getIsTablet()
    ? Devices.Tablet
    : Devices.Desktop;
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [isTablet, setIsTablet] = useState(getIsTablet());
  const [isDesktop, setIsDesktop] = useState(getIsDesktop());
  const [currentDevice, setCurrentDevice] = useState(getCurrentDevice());

  const onSizeUpdate = useCallback(() => {
    setIsMobile(getIsMobile());
    setIsTablet(getIsTablet());
    setIsDesktop(getIsDesktop());
    setCurrentDevice(getCurrentDevice());
  }, []);
  useLayoutEffect(() => {
    window.addEventListener('resize', onSizeUpdate);
    return () => window.removeEventListener('resize', onSizeUpdate);
  }, [onSizeUpdate]);
  return { isMobile, isTablet, isDesktop, currentDevice };
};

export const MobileHidden = React.memo(({ children }) => {
  const { isMobile } = useIsMobile();
  if (isMobile) return null;
  return children;
});

export const MobileVisible = React.memo(({ children }) => {
  const { isMobile } = useIsMobile();
  if (!isMobile) return null;
  return children;
});

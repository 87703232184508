import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import withMobileVisibility from '../../services/withMobileVisibility';

class Menu extends Component {
  render() {
    const { isDesktop } = this.props;

    return (
      <div>
        <nav
          className="navbar navbar-expand-xl navbar-light"
          style={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.33)',
            padding: '10px 20px',
          }}
        >
          <Link to="/" className="navbar-brand">
            <img
              src="/img/monastays-logo-2.svg"
              alt="Monastays logo"
              style={{ maxHeight: '40px' }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav ml-auto"
              style={
                !isDesktop ? { width: '100%', alignItems: 'center' } : undefined
              }
            >
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/" className="nav-link monastays_nav_link">
                  <FormattedMessage id="Menu.Home" defaultMessage="Naslovna" />
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/deals" className="nav-link monastays_nav_link">
                  <FormattedMessage id="Menu.offers" defaultMessage="Offers" />
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/search" className="nav-link monastays_nav_link">
                  <FormattedMessage
                    id="Menu.Search"
                    defaultMessage="Samostani"
                  />
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <a
                  href="https://monastays-blog.webflow.io"
                  className="nav-link monastays_nav_link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="Menu.blog" defaultMessage="Blog" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default withMobileVisibility(Menu);

import _ from 'lodash';
import {
  PUSH_CHOSEN_SUITES,
  SET_AVAILABILITY_ERROR,
  REMOVE_CHOSEN_SUITE,
  CALCULATE_PRICE_SUCCESS,
  RESET_SUITE_AVAILABILITY,
  SET_BOOKING_DATE,
  SET_DATES_ERROR,
  REMOVE_ACCOMMODATION_INFO,
  SAVE_PERSONAL_INFO,
  SAVE_FORM_DATA,
  SET_PERSONS,
  SET_SELECTED_OPTION,
  RESET_CHOSEN_SUITES,
  BOOKING_EVENT,
} from '../constants/actionTypes';

const defaultState = {
  chosenSuites: {},
  calculatedPrice: null,
  totalDiscountPercent: 0,
  dates: {
    dateFrom: null,
    dateTo: null,
  },
  persons: {
    total: 0,
    children: 0,
    adults: 0,
  },
  datesNotSetErr: false,
  formData: {},
  personalInfo: {},
  selectedOptions: [],
  currency: null,
  isEvent: false,
};

const booking = (state = defaultState, { type, payload }) => {
  switch (type) {
    case PUSH_CHOSEN_SUITES:
      return {
        ...state,
        chosenSuites: {
          ...state.chosenSuites,
          [payload.id]: payload,
        },
      };
    case BOOKING_EVENT:
      return {
        ...state,
        isEvent: true,
      };
    case REMOVE_CHOSEN_SUITE:
      return {
        ...state,
        chosenSuites: _.omit(state.chosenSuites, payload.id),
      };
    case RESET_CHOSEN_SUITES:
      return {
        ...state,
        chosenSuites: payload.data,
      };
    case RESET_SUITE_AVAILABILITY:
      return {
        ...state,
        chosenSuites: Object.assign(
          {},
          ...Object.keys(state.chosenSuites).map(suite => ({
            [suite]: _.omit(state.chosenSuites[suite], 'onlyAvailable'),
          }))
        ),
      };
    case SET_AVAILABILITY_ERROR:
      return {
        ...state,
        chosenSuites: {
          ...state.chosenSuites,
          [payload.id]: {
            ...state.chosenSuites[payload.id],
            onlyAvailable: payload.onlyAvailable,
          },
        },
      };
    case CALCULATE_PRICE_SUCCESS:
      return {
        ...state,
        calculatedPrice: payload.data.TotalBasePrice,
        reservationPrice: payload.data.reservationPrice,
        accommodationPrice: payload.data.accommodationPrice,
        realBasePrice: payload.data.RealBasePrice,
        totalDiscountPercent: payload.data.totalDiscountPercent,
        additionalPrice: payload.data.additionalPrice,
        currency: payload.data.currency,
      };
    case SET_BOOKING_DATE:
      return {
        ...state,
        dates: {
          ...state.dates,
          [payload.label]: payload.date,
        },
      };
    case SET_DATES_ERROR:
      return {
        ...state,
        datesNotSetErr: payload,
      };
    case SAVE_PERSONAL_INFO: {
      return {
        ...state,
        personalInfo: payload.info,
      };
    }
    case SAVE_FORM_DATA: {
      return {
        ...state,
        formData: payload.data,
      };
    }
    case SET_PERSONS: {
      return {
        ...state,
        persons: payload.data,
      };
    }
    case SET_SELECTED_OPTION: {
      return {
        ...state,
        selectedOptions: payload.data,
      };
    }
    case REMOVE_ACCOMMODATION_INFO: {
      return {
        ...defaultState,
      };
    }
    default:
      return state;
  }
};

export default booking;

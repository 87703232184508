import axios from 'axios';

import { getApiHostname } from './services/resolveStage';

const client = axios.create({
  baseURL: getApiHostname(),
  responseType: 'json',
});

export default client;

import { Component } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";

ReactGA.initialize("UA-118294153-2");

class ScrollToTop extends Component {
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname + this.props.location.search);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      ReactGA.pageview(
        this.props.location.pathname + this.props.location.search
      );
      if (this.props.location.pathname !== prevProps.location.pathname)
        window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import localeData from "../../public/locales/data.json";
import { languageSelector } from '../reducers/settings/selectors';

class ConnectedIntlProvider extends Component {
  render() {
    const { language, children } = this.props;
    return (
      <IntlProvider locale={language} messages={localeData[language]}>
        {children}
      </IntlProvider>
    );
  }
}

export default connect(
  state => ({
    language: languageSelector(state),
  }),
)(ConnectedIntlProvider);

import React, { useCallback } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../Button';
import { Container, Text, Content } from './styledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { setCookiesPolicyDismissed } from '../../reducers/settings/actions';
import { cookiesPolicyDismissedSelector } from '../../reducers/settings/selectors';

const ButtonContainer = styled.div`
  width: 286px;
`;

const CookiesGDPR = () => {
  const dispatch = useDispatch();
  const hideCookiesPolicy = useSelector(cookiesPolicyDismissedSelector);
  const dismissNotice = useCallback(
    () => dispatch(setCookiesPolicyDismissed()),
    [dispatch],
  );

  if (hideCookiesPolicy) return null;

  return (
    <Container>
      <Content>
        <Text>
          <FormattedMessage
            id="Footer.gdpr"
            defaultMessage="Monastays.com koristi kolačiće (cookies) kako bi poboljšali korisničko iskustvo, funkcionalnost stranice i pretraživanja oglasa. Više o kolačićima možete pročitati {link}"
          />
          <Link to="/cookies">
            <FormattedMessage id="GroupTravel.Link" />
          </Link>
        </Text>
        <ButtonContainer>
          <Button onClick={dismissNotice} noTextTransform stretch>
            <FormattedMessage id="Footer.gdpr.agree" />
          </Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default injectIntl(React.memo(CookiesGDPR));

export const ACCOMMODATIONS_LOAD = 'ACCOMMODATION_LOAD';
export const ACCOMMODATIONS_LOAD_SUCCESS = 'ACCOMMODATION_LOAD_SUCCESS';
export const ACCOMMODATIONS_LOAD_FAIL = 'ACCOMMODATION_LOAD_FAIL';
export const REVIEWS_LOAD = 'REVIEWS_LOAD';
export const REVIEWS_LOAD_SUCCESS = 'REVIEWS_LOAD_SUCCESS';
export const REVIEWS_LOAD_FAIL = 'REVIEWS_LOAD_FAIL';
export const GET_USER_LOCALES = 'GET_USER_LOCALES';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CURRENT_ACCOMMODATION_LOAD_SUCCESS = 'CURRENT_ACCOMMODATION_LOAD_SUCCESS';
export const CLEAR_CURRENT_ACCOMMODATION = 'CLEAR_CURRENT_ACCOMMODATION';

export const PUSH_CHOSEN_SUITES = 'PUSH_CHOSEN_SUITES';
export const SET_AVAILABILITY_ERROR = 'SET_AVAILABILITY_ERROR';
export const REMOVE_CHOSEN_SUITE = 'REMOVE_CHOSEN_SUITE';
export const CALCULATE_PRICE = 'CALCULATE_PRICE';
export const CALCULATE_PRICE_SUCCESS = 'CALCULATE_PRICE_SUCCESS';
export const RESET_SUITE_AVAILABILITY = 'RESET_SUITE_AVAILABILITY';
export const SET_BOOKING_DATE = 'SET_BOOKING_DATE';
export const SET_DATES_ERROR = 'SET_DATES_ERROR';
export const REMOVE_ACCOMMODATION_INFO = 'REMOVE_ACCOMMODATION_INFO';
export const SAVE_PERSONAL_INFO = 'SAVE_PERSONAL_INFO';
export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';
export const SET_PERSONS = 'SET_PERSONS';
export const SET_SELECTED_OPTION = 'SET_SELECTED_OPTION';
export const RESET_CHOSEN_SUITES = 'RESET_CHOSEN_SUITES';
export const BOOKING_EVENT = 'BOOKING_EVENT';

export const FILTER_ACCOMMODATIONS_LOAD = 'FILTER_ACCOMMODATIONS_LOAD';
export const FILTER_ACCOMMODATIONS_LOAD_SUCCESS = 'FILTER_ACCOMMODATIONS_LOAD_SUCCESS';
export const FILTER_ACCOMMODATIONS_LOAD_FAIL = 'FILTER_ACCOMMODATIONS_LOAD_FAIL';

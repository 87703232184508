import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';


import { setCurrency, setLanguage, setCookiesPolicyDismissed } from './actions';

const language = handleActions({
  [setLanguage]: (state, action) => action.payload,
}, null);

const currency = handleActions({
  [setCurrency]: (state, action) => action.payload,
}, null);

const cookiesPolicyDismissed = handleActions({
  [setCookiesPolicyDismissed]: (state, action) => true,
}, false);

export const persistConfig = {
  key: 'settings',
  storage: localForage,
}

export default persistReducer(persistConfig, combineReducers({ language, currency, cookiesPolicyDismissed }));

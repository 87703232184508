import React from 'react';

import { useIsMobile } from '../theme';

const withMobileVisibility = Component => {
  return props => {
    const { isMobile, isDesktop, currentDevice } = useIsMobile();
    return (
      <Component
        isMobile={isMobile}
        isDesktop={isDesktop}
        currentDevice={currentDevice}
        {...props}
      />
    );
  };
};

export default withMobileVisibility;

export const LANGUAGES = [
  { value: 'English', code: 'en' },
  { value: 'Hrvatski', code: 'hr' },
  { value: 'Deutsch', code: 'de' },
  // { value: 'Français', code: 'fr' },
  // { value: 'Español', code: 'es' },
  // { value: 'Italiano', code: 'it' },
  // { value: 'Polski', code: 'pl' }
];

export const DEFAULT_LANGUAGE = { value: 'Hrvatski', code: 'hr' };

export const countryLanguageMapper = {
  hr: 'hr',
  de: 'de',
  at: 'de'
}

export const CURRENCY = {
  hrk: 'hrk',
  ils: 'ils',
  usd: 'usd',
  eur: 'eur'
}

export const currencyCountryMapper = {
  hr: CURRENCY.hrk,
  il: CURRENCY.ils,
  us: CURRENCY.usd,
  de: CURRENCY.eur,
  at: CURRENCY.eur
}

export const currencyCharMapper = {
  [CURRENCY.hrk]: 'kn',
  [CURRENCY.ils]: '₪',
  [CURRENCY.usd]: '$',
  [CURRENCY.eur]: '€'
}
import React from 'react';

import { ButtonContainer } from './styledComponents';

const Button = ({
  children,
  onClick,
  disabled,
  noTextTransform,
  stretch,
  ...props
}) => (
  <ButtonContainer
    noTextTransform={noTextTransform}
    onClick={disabled ? () => {} : onClick}
    disabled={disabled}
    stretch={stretch}
    {...props}
  >
    {children}
  </ButtonContainer>
);

export default React.memo(Button);

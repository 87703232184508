// Production URL: https://monastays.com/
// Staging URL: http://upwork-monastays.azurewebsites.net/

const prodApi = 'https://backend.monastays.com/API';
const stagingApi = 'http://167.71.38.147/API';

export const isProd = () => {
  return window.location.origin.includes('https://monastays.com');
};

export const isStaging = () => {
  return window.location.origin.includes(
    'http://upwork-monastays.azurewebsites.net',
  );
};

export const getApiHostname = () => {
  return isProd() ? prodApi : stagingApi;
};

import {
  ACCOMMODATIONS_LOAD,
  ACCOMMODATIONS_LOAD_SUCCESS,
  ACCOMMODATIONS_LOAD_FAIL,
  CURRENT_ACCOMMODATION_LOAD_SUCCESS,
  FILTER_ACCOMMODATIONS_LOAD,
  FILTER_ACCOMMODATIONS_LOAD_SUCCESS,
  FILTER_ACCOMMODATIONS_LOAD_FAIL,
  CLEAR_CURRENT_ACCOMMODATION,
} from '../constants/actionTypes';

const defaultState = {
  accommodationList: [],
  accommodationSearch: [],
  currentAccommodation: null,
  loading: false,
  error: false,
  filter: {},
};

const accommodation = (state = defaultState, { type, error, payload }) => {
  switch (type) {
    case ACCOMMODATIONS_LOAD:
    case FILTER_ACCOMMODATIONS_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACCOMMODATIONS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        accommodationList: payload.data.profiles,
      };
    case ACCOMMODATIONS_LOAD_FAIL:
    case FILTER_ACCOMMODATIONS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: error,
      };
    case CURRENT_ACCOMMODATION_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAccommodation: payload.data,
      };
    case CLEAR_CURRENT_ACCOMMODATION:
      return {
        ...state,
        currentAccommodation: null,
      };
    case FILTER_ACCOMMODATIONS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        accommodationSearch: payload.data,
      };
    default:
      return state;
  }
};

export default accommodation;

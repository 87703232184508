import axios from "axios";

import {
  countryLanguageMapper,
  CURRENCY,
  currencyCountryMapper,
} from "../../constants/languages";
import {
  languageSelectorWithoutDefault,
  currencySelectorWithoutDefault,
} from "./selectors";
import { getCountyCodeByIpLookup } from "../../utils";
import { setLanguage, setCurrency } from "./actions";

export const getUserLocale = () => async (dispatch, getState) => {
  const state = getState();
  const stateCurrency = currencySelectorWithoutDefault(state);
  const stateLanguage = languageSelectorWithoutDefault(state);
  if (stateLanguage) {
    axios.defaults.headers.common["Accept-Language"] = stateLanguage;
  }
  if (stateCurrency) {
    axios.defaults.headers.common["Currency"] = stateCurrency;
  }
  if (stateLanguage && stateCurrency) return;

  const countryCode = await getCountyCodeByIpLookup();
  if (!countryCode) {
    if (!stateLanguage) {
      axios.defaults.headers.common["Accept-Language"] = "en";
    }
    if (!stateCurrency) {
      axios.defaults.headers.common["Currency"] = CURRENCY.eur;
    }
    return;
  }

  if (!stateLanguage) {
    const language = countryLanguageMapper[countryCode] || "en";
    axios.defaults.headers.common["Accept-Language"] = language;
    dispatch(setLanguage(language));
  }

  if (!stateCurrency) {
    const currency = currencyCountryMapper[countryCode] || CURRENCY.eur;
    axios.defaults.headers.common["Currency"] = currency;
    dispatch(setCurrency(currency));
  }
};

export const switchUserLanguage = (language) => async (dispatch) => {
  axios.defaults.headers.common["Accept-Language"] = language;
  dispatch(setLanguage(language));
};

export const switchUserCurrency = (currency) => async (dispatch) => {
  axios.defaults.headers.common["Currency"] = currency;
  dispatch(setCurrency(currency));
};

import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

import { loadEvents, loadEventsFailure, loadEventsSuccess, loadEventDetails, loadEventDetailsFailure, loadEventDetailsSuccess } from './actions';

const list = handleActions({
  [loadEventsSuccess]: (state, action) => action.payload.data.events,
}, []);

const details = handleActions({
  [loadEventDetailsSuccess]: (state, action) => action.payload.data,
}, null);

const loading = handleActions({
  [loadEvents]: () => true,
  [loadEventsFailure]: () => false,
  [loadEventsSuccess]: () => false,
  [loadEventDetails]: () => true,
  [loadEventDetailsFailure]: () => false,
  [loadEventDetailsSuccess]: () => false,
}, null);

const error = handleActions({
  [loadEvents]: () => false,
  [loadEventsFailure]: () => true,
  [loadEventsSuccess]: () => false,
  [loadEventDetails]: () => true,
  [loadEventDetailsFailure]: () => false,
  [loadEventDetailsSuccess]: () => false,
}, false);


export default combineReducers({ list, details, loading, error });

import { combineReducers } from 'redux';
import accommodation from './accommodation';
import review from './review';
import locales from './locales';
import booking from './booking';
import settings from './settings';
import events from './events';

export default combineReducers({
  accommodation,
  review,
  locales,
  booking,
  settings,
  events
});

import styled, { css } from 'styled-components';

import { Colors } from '../../theme';

export const ButtonContainer = styled.div`
  width: ${props => (props.stretch ? '100%' : 'fit-content')};
  border-radius: 8px;
  color: ${Colors.White};
  background-color: ${Colors.Blue};
  max-height: 100%;
  ${({ inverted }) =>
    inverted &&
    css`
      color: ${Colors.Blue};
      border: 2px solid ${Colors.Blue};
      background-color: ${Colors.White};
    `}

  ${({ white }) =>
    white &&
    css`
      color: ${Colors.Dark};
      background-color: ${Colors.White};
      box-shadow: 0px 1px 1px 0px #737373;
    `}

  text-transform: ${props => (props.noTextTransform ? 'none' : 'uppercase')};
  font-family: Montserrat, sans-serif;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '15px')};
  font-weight: ${({ bold }) => (bold ? 600 : 500)};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? `${height}px` : '48px')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  :hover {
    color: ${Colors.White};
    background: ${({ disabled }) => !disabled && Colors.Orange};
    border: 0px;
  }


  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Colors.White};
      background-color: ${Colors.BorderGray};
      box-shadow: 0px 1px 1px 0px #737373;
    `}
`;
